<template>
  <div>
    <div v-if="motif">
      <h5>Product details</h5>
      <div class="d-table product-details">
        <div class="d-table-row">
          <div class="d-table-cell"><div>Product</div></div>
          <div class="d-table-cell"><div>{{ motif.ProductName }}</div></div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Motif</div></div>
          <div class="d-table-cell"><div>{{ motif.MotifName }}</div></div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Stock</div></div>
          <div class="d-table-cell"><div>{{ motif.Stock | format }}</div></div>
        </div>
        <div class="d-table-row filler"><div class="d-table-cell"><div>&nbsp;</div></div></div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Collection</div></div>
          <div class="d-table-cell"><div>{{ motif.CollectionName }}</div></div>
        </div>
        <!-- Store groups -->
        <div class="d-table-row">
          <div class="d-table-cell"><div>Event group name</div></div>
          <div class="d-table-cell"><div>{{ motif.EventGroupName }}</div></div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Event name</div></div>
          <div class="d-table-cell"><div>{{ motif.EventName }}</div></div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Class name</div></div>
          <div class="d-table-cell"><div>{{ motif.ClassName }}</div></div>
        </div>
        <div class="d-table-row filler"><div class="d-table-cell"><div>&nbsp;</div></div></div>
        <!-- Categories -->
        <div class="d-table-row">
          <div class="d-table-cell"><div>Category NW</div></div>
          <div class="d-table-cell"><div>{{ motif.CategoryNewwave }}</div></div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Category Mall</div></div>
          <div class="d-table-cell"><div>{{ motif.CategoryMall }}</div></div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Category Heureka CZ</div></div>
          <div class="d-table-cell"><div>{{ motif.CategoryHeurekaCz }}</div></div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Category Heureka SK</div></div>
          <div class="d-table-cell"><div>{{ motif.CategoryHeurekaSk }}</div></div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Category Zboží</div></div>
          <div class="d-table-cell"><div>{{ motif.CategoryZbozi }}</div></div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Category Google</div></div>
          <div class="d-table-cell"><div>{{ motif.CategoryGoogle }}</div></div>
        </div>
        <div class="d-table-row">
          <div class="d-table-cell"><div>Category eMag</div></div>
          <div class="d-table-cell"><div>{{ motif.CategoryEmag }}</div></div>
        </div>
      </div>
    </div>
    <div v-else>
      <h5>No results. <span v-if="fetchingMotif">Gathering product details..</span></h5>
      <b-alert v-if="fetchingMotifError.length" show variant="danger">
        Failed to find product details
      </b-alert>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeedNoResults',
  components: {},
  props: {
    searchText: String,
  },
  data() {
    return {
      fetchingMotif: false,
      fetchingMotifError: '',
      motif: null,
    };
  },
  methods: {
    fetchMotif() {
      this.fetchingMotif = true;
      this.$http
        .get('https://wamas.online/api/select/getMotif')
        .query({ key: this.searchText })
        .then((res) => {
          if (res.body.motif === null) {
            this.fetchingMotifError = 'Motif not found';
          } else {
            this.motif = res.body.motif;
          }
        })
        .catch((err) => {
          this.fetchingMotifError = err.response.text;
        })
        .finally(() => {
          this.fetchingMotif = false;
        });
    },
  },
  created() {
    if (this.searchText.length > 0) {
      this.fetchMotif();
    }
  },
};
</script>

<style lang="scss" scoped>
.product-details {
  .d-table-cell {
    padding-top: 5px;
  }
  .d-table-cell>div {
    padding: 6px 12px;
    border: 1px solid #cfcdce;
    border-radius: 0.1rem;
    white-space: nowrap;
  }

  .d-table-cell:nth-child(1)>div {
    width: 150px;
    background-color: #f3f3f3;
    font-weight: bold;
  }

  .d-table-cell:nth-child(2)>div {
    margin-left: 10px;
    background-color: #fff;

    &:after {
      content: '\00a0\00a0';
      font-size: 0
    }
  }

  .filler {
    visibility: hidden;
  }
}
</style>
